@media (min-width: 768px) {
  .BetCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .BetCard .item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 33%;
    height: 100%;
  }
  .BetCard .item .ant-card-actions,
  .BetCard .item .ant-card-head {
    width: 100%;
  }
}

.BetCard .item {
  text-align: left;
}

.date {
  margin-top: 10px;
  color: #888888;
  left: -2px;
}
